import { io } from 'socket.io-client'

export default {
  install: (app, { connection, options }) => {
    const socket = io(connection, options)
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$socket = socket
    app.provide('socket', socket)
  }
}
