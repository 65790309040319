import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

import { createApp } from 'vue'
import VueCookies from 'vue3-cookies'
import SocketIO from '@/plugins/socket.io'
import router from './router'
import store from './store'
import App from './App.vue'

const app = createApp(App)
  .use(VueCookies, {
    expireTimes: '30d',
    secure: true
  })
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(SocketIO, {
    connection: process.env.VUE_APP_SOCKETIO_SERVER,
    options: {}
  })

app.mount('#app')
